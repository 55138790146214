import { extendBaseTheme, extendTheme } from '@chakra-ui/react';
import typography from '../../theme/typography';
import breakpoints from '../../theme/breakpoints';
import textStyles from '../../theme/foundations/textStyles';
import colors from '../../theme/foundations/colors';
import sizes from '../../theme/foundations/sizes';
import spacing from '../../theme/foundations/spacing';
import LinkStyles from '../../components/uikit/Link/Link.styles';
import ButtonStyles from '../../components/uikit/Button/Button.styles';
import DividerStyles from '../../components/uikit/Divider/Divider.styles';
import ContainerStyles from '../../components/layout/Container/Container.styles';
import { CalendlyModalStyles } from '../../components/CalendlyModal/CalendlyModal.styles';

const theme = {
  fonts: {
    body: `'JetBrains Mono', monospace`,
    heading: `'JetBrains Mono', monospace`,
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'body.disableScroll': {
        overflow: 'hidden',
        overscrollBehavior: 'contain',
        position: 'relative',
        pl: 0,
        pt: 0,
        pr: 0,
        ml: 0,
        mt: 0,
        mr: ' 0px',
      },
    },
  },
  ...typography,
  ...spacing,
  textStyles,
  colors,
  sizes,
  components: {
    Link: LinkStyles,
    Button: ButtonStyles,
    Divider: DividerStyles,
    Container: ContainerStyles,
    Modal: CalendlyModalStyles,
  },
  config: {
    useSystemColorMode: false,
  },
  breakpoints,
};

export default extendTheme(theme);
// small hack to remove annoying gatsby error caused by shadowing the theme
export const baseTheme = extendBaseTheme({});
